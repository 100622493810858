import Image from "../Image";
import profile from "../../images/table/default_profile.svg";

import { RxCross2 } from "react-icons/rx";
import { BsArrowCounterclockwise } from "react-icons/bs";

const CourtsDisplay = ({
  name,
  city,
  court_rate,
  created_at,
  deleted_at,
  id,
  latitude,
  longitude,
  platform_charges,
  user_id,
  owner_name,
  owner_phone,
  owner_email,
  owner_id,
  images,
  deleteUser,
  court,
  ...otherProps
}) => {
  return (
    <div
      className={`px-6 py-6  rounded-2xl min-w-fit relative  cursor-pointer overflow-hidden hover:opacity-70 ${
        deleted_at === null ? "bg-greyCustom" : "bg-red-200"
      }`}
      {...otherProps}
    >
      {deleted_at === null ? (
        <div className="absolute top-2.5 right-2.5 p-0.5 bg-red-800 rounded-full z-10 hover:opacity-75">
          <RxCross2
            size={12}
            className="mx-auto my-auto text-white"
            onClick={(e) => {
              deleteUser(court);
              e.stopPropagation();
            }}
          />
        </div>
      ) : (
        <div className="absolute top-2.5 right-2.5 p-0.5 bg-green-800  rounded-full z-10 hover:opacity-75">
          <BsArrowCounterclockwise
            size={12}
            className="mx-auto my-auto text-white"
            onClick={(e) => {
              deleteUser(court);
              e.stopPropagation();
            }}
          />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 justify-between gap-2 md:gap-8 items-center text-center md:text-start ">
        <div className="md:p-6">
          <h4 className="font-bold text-base 2xl:text-2xl uppercase">{name}</h4>

          <div className="grid grid-cols-1 md:grid-cols-2 text-xs 2xl:text-sm  mt-4 gap-3 md:gap-0">
            <p className="font-semibold ">City</p>
            <p className="font-light  ml-3">{city}</p>

            <p className="font-semibold  ">Name</p>
            <p className="font-light  line-clamp-1 ml-3">{owner_name}</p>

            <p className="font-semibold ">Phone</p>
            <p className="font-light  ml-3">{owner_phone}</p>

            <p className="font-semibold ">Email</p>
            <p className="font-light  ml-3">{owner_email}</p>

            <p className="font-semibold ">Latitude</p>
            <p className="font-light  ml-3">{latitude}</p>

            <p className="font-semibold ">Longitude</p>
            <p className="font-light ml-3">{longitude}</p>
          </div>
        </div>

        <div className="md:justify-self-end md:ml-4">
          <Image
            src={
              images.length > 0
                ? `${process.env.REACT_APP_IMAGE}/${images[0].image}`
                : profile
            }
            className="h-16 w-16 2xl:h-32 2xl:w-32  rounded-xl "
          />
        </div>
      </div>

      <span className="text-sm 2xl:text-base text-right flex flex-row md:justify-end mt-5 md:mt-0 gap-3">
        Price: <strong>${court_rate}/per hour</strong>
      </span>
    </div>
  );
};

export default CourtsDisplay;
