import axios from "axios";
import { useEffect, useState } from "react";
import Cards from "../../../components/Dashboard/Cards";
import ErrorDisplay from "../../../components/ErrorHandling/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";

const Stats = () => {
  //For showing loader while API does its work
  const [loaded, setLoaded] = useState(false);
  const [bookingsToday, setBookingsToday] = useState();
  const [receivable, setReceivable] = useState();
  const [error, setError] = useState("");

  //For searching
  useEffect(() => {
    fetchingData();
  }, []);

  const fetchingData = (page) => {
    setLoaded(false);
    setError("");

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/total-bookings`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);

        setBookingsToday(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        return Promise.reject();
      });

    const promise1 = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/receivable`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);

        setReceivable(res.data.data && parseInt(res.data.data));
      })

      // Catch errors if any
      .catch((err) => {
        return Promise.reject();
      });

    Promise.all([promise, promise1]).then((values) => {
      setError("An error has occurred");
      setLoaded(true);
    });
  };

  if (loaded === false) {
    return (
      <div className="mt-28 ">
        <PageLoader type="ThreeDots" />
        <div className="flex flex-row justify-center">
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </div>
      </div>
    );
  } else
    return (
      <>
        <div className="flex flex-row flex-wrap gap-5 p-12 items-center justify-center md:justify-start">
          <Cards
            heading={"Pending Bookings"}
            number={bookingsToday}
            link="/AD/payments"
          />
          <Cards
            heading={"Payments to be recieved"}
            number={receivable}
            link="/AD/payments"
          />
        </div>
      </>
    );
};

export default Stats;
