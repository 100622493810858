import logo from "../images/email_sent.svg";
import { Link } from "react-router-dom";

const EmailSent = () => {
  return (
    <div className="grid h-screen w-full place-items-center bg-primary overflow-auto">
      <div className=" flex flex-col md:flex-col items-center text-center gap-6  p-2  justify-start text-white">
        <img className="h-16 md:h-20 2xl:h-36" src={logo} alt="" />

        <h1 className="text-3xl font-bold">Email Sent</h1>

        <h3 className="font-thin 2xl:text-xl">
          Instructions to reset your password <br /> has been sent to your email
          address
        </h3>

        <Link
          to={"/login"}
          className=" px-12 py-3 pink rounded-2xl text-white  self-center text-base black-hover"
          href=""
        >
          Go Back
        </Link>
      </div>
    </div>
  );
};

export default EmailSent;
