import { MdEdit } from "react-icons/md";

const EditButton = ({ size = 31, className, ...otherProps }) => {
  return (
    <MdEdit
      disableripple
      size={size}
      className={`edit-icon p-1 rounded cursor-pointer ${className}`} //styles defined in app.css
      {...otherProps}
    />
  );
};

export default EditButton;
