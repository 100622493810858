import { useNavigate } from "react-router-dom";
import { kFormatter } from "../Functions";

const Cards = ({ heading, number, link }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="bg-card-pattern bg-cover p-4 h-72 w-64 min-w-fit rounded-xl text-left hover:opacity-75 cursor-pointer"
        onClick={() => navigate(link)}
      >
        <div className="mt-5">
          <p className="text-xl font-semibold text-white">{heading}</p>
          <p className="text-6xl font-semibold text-white mt-2 overflow-hidden">
            {kFormatter(number)}
          </p>
        </div>
      </div>
    </>
  );
};

export default Cards;
