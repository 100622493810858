const Cancel = ({ ...otherProps }) => {
  return (
    <input
      className="red p-3 font-thin   rounded-lg text-white cursor-pointer" //styling in app.css
      {...otherProps}
    />
  );
};

export default Cancel;
