import { NavLink, Outlet, useLocation } from "react-router-dom";

const Dashboard = () => {
  const location = useLocation();
  let currentPath = location.pathname;
  currentPath = currentPath.substring(1);

  return (
    <div>
      <div className="px-12  mt-4 flex flex-row justify-start items-center gap-12 border-b-2 border-black border-opacity-10">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "p-4 border-b-4 border-primary"
              : "" + currentPath === "SU/dashboard"
              ? "p-4 border-b-4 border-primary"
              : "p-4 border-b-4 border-transparent"
          }
          to={"overview"}
        >
          <span className="font-medium text-base 2xl:text-xl">Overview</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "p-4 border-b-4 border-primary"
              : "p-4 border-b-4 border-transparent"
          }
          to={"stats"}
        >
          <span className="font-medium text-base 2xl:text-xl">Statistics</span>
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Dashboard;
