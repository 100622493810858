import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";

import Modal from "../../../components/Modal";
import FormImageField from "../../../components/Form/FormImageField";
import FormInputField from "../../../components/Form/FormInputField";
import FormSelectField from "../../../components/Form/FormSelectField";
import {
  formatDate,
  formatTempDate,
  roleStyling,
} from "../../../components/Functions";
import EditButton from "../../../components/MaterialTableButtons/EditButton";
import DeleteButton from "../../../components/MaterialTableButtons/DeleteButton";
import ReEnableButton from "../../../components/MaterialTableButtons/ReEnableButton";
import FormPasswordField from "../../../components/Form/FormPasswordField";
import FormReactSelect from "./../../../components/Form/FormReactSelect";

const Users = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [role_check, setRole_check] = useState();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [idStatus, setIdStatus] = useState();
  const [supervisor, setSuperVisor] = useState([]);
  const [grade, setGrade] = useState([]);
  const [officeDetails, getOfficeDetails] = useState({
    customID: false,
  });
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    status: "",
    designation: "",
    email: "",
    phone: "",
    JoiningDate: "",
    supervisedBy: {
      username: "",
      id: "",
    },
    shiftId: {
      name: "",
      id: "",
    },
    id: "",
    address: "",
    photo: "",
  });

  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");
  const [shifts, setShifts] = useState([]);
  // For refreshing table
  const tableRef = useRef();
  const modalRef = useRef(null);

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For filtering
  const [userFilter, setUserFilter] = useState("free-user");

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);

    if (rowData.isGrade === true) {
      setIdStatus("grade");
      reset({
        username: rowData.username,
        email: rowData.email,
        nic: rowData.nic,
        phone: rowData.phone,
        dateOfBirth: formatDate(rowData.dateOfBirth),
        gender: rowData.gender,
        test: rowData.test,
        role: rowData.role,
        designation: rowData.designation,
        department: rowData.department,
        supervisedBy: rowData.supervisedBy && rowData.supervisedBy.id,
        shiftId: rowData.shiftId && rowData.shiftId.id,
        JoiningDate: formatDate(rowData.JoiningDate),
        emerg_name: rowData.emerg_name,
        emerg_contact: rowData.emerg_contact,
      });

      setEditModal(true);
    } else {
      setIdStatus("manual");
      reset({
        username: rowData.username,
        email: rowData.email,
        nic: rowData.nic,
        phone: rowData.phone,
        dateOfBirth: formatDate(rowData.dateOfBirth),
        gender: rowData.gender,
        test: rowData.test,
        role: rowData.role,
        designation: rowData.designation,
        department: rowData.department,
        supervisedBy: rowData.supervisedBy && rowData.supervisedBy.id,
        shiftId: rowData.shiftId && rowData.shiftId.id,
        JoiningDate: formatDate(rowData.JoiningDate),
        emerg_name: rowData.emerg_name,
        emerg_contact: rowData.emerg_contact,
      });

      setEditModal(true);
    }
    setEditModal(true);
  };

  useEffect(() => {}, []); // <-- empty dependency array

  //Adding user
  const HandleForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    // console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Added ",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users/${selectedUser.id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated ",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/users/${selectedUser.id}`,
        method: `DELETE`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleRestoreForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/user-restore/${selectedUser.id}`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated",
        error: "An error has occurred",
      });
    }
  };

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function () {
      status = request.status;
      if (request.status == 200) {
        //if(statusText == OK)
        console.log("image exists");
      } else {
        console.log("image doesn't exist");
      }
    };
  }

  const columns = [
    // {
    //   title: "",
    //   field: "photo",
    //   filtering: false,
    //   render: (rowData) => (
    //     <Image
    //       src={
    //         rowData.photo === "no-photo.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.photo}`
    //       }
    //       className="h-10 rounded-full"
    //     />
    //   ),
    // },
    {
      title: "NAME",
      field: "name",
      filtering: false,
      render: (rowData) => (
        <>
          <p className="font-bold text-base">{rowData.name}</p>
          <p>{rowData.email}</p>
        </>
      ),
    },
    { title: "PHONE", field: "phone", filtering: false },
    // {
    //   title: "ROLE",
    //   field: "role",
    //   filtering: false,
    //   render: (rowData) => (
    //     <div style={roleStyling(rowData.role)}>{rowData.role}</div>
    //   ),
    // },
    // { title: "USER ID", field: "test", filtering: false },

    {
      title: "GENDER",
      field: "gender",
      filtering: false,
    },
    {
      title: "CREATED DATE",
      field: "created_at",
      filtering: false,
      render: (rowData) => formatDate(rowData.created_at),
    },
    {
      title: "STATUS",
      field: "status",
      filtering: false,
      render: (rowData) => (
        <div
          style={
            rowData.deleted_at
              ? {
                  background: "#C3222247",
                  color: "#C32222",
                  border: "1px solid #C32222",
                  borderRadius: "8px",
                  width: "5rem",
                  padding: "3px",
                  textAlign: "center",
                }
              : {
                  background: "#55ac1c47",
                  border: "1px solid #55AC1C",
                  borderRadius: "8px",
                  color: "#55AC1C",
                  width: "5rem",
                  padding: "3px",
                  textAlign: "center",
                }
          }
        >
          {rowData.deleted_at ? "OFFLINE " : "ACTIVE "}
        </div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col md:flex-row  gap-6 mb-4 md:absolute z-10 right-10">
          <select
            className="input-form"
            value={userFilter}
            onChange={(e) => {
              setUserFilter(e.target.value);
              refreshTable();
            }}
          >
            <option value={"free-user"}>Free Users</option>
            <option value={"paid-user"}>Paid Users</option>
            <option value={"court-user"}>Court Owners</option>
          </select>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,
            loadingType: "linear",
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            // {
            //   icon: () => <EditButton />,
            //   onClick: (event, rowData) => {
            //     reset();
            //     editUser(rowData);
            //     setEditForm(false);
            //   },
            // },
            (rowData) => ({
              icon: () =>
                rowData.deleted_at ? <ReEnableButton /> : <DeleteButton />,

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/users?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value[0] && filter.value.length == 1)
                    return `&${filter.column.field}${filter.operator}${filter.value[0]}`;
                });

                url += filter.join("");
              }

              if (userFilter.length) {
                url += `&user_type=${userFilter}`;
              }

              if (query.search) {
                url += "&name=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data.data);
                  resolve({
                    data: result.data.data,
                    page: query.page,
                    totalCount: result.data.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add User Modal */}
      <Modal
        show={modal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center "
          >
            <FormImageField name={"file"} />

            <div>
              <h2 className="text-lg font-bold mb-2">Enter User Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  placeholder="Name*"
                  required
                  name={"username"}
                />

                <FormInputField
                  name={"email"}
                  required
                  type="email"
                  autocomplete="new-password"
                  placeholder="Email*"
                />

                <FormPasswordField
                  autocomplete="new-password"
                  required
                  placeholder="Password*"
                  name={"password"}
                />

                <FormInputField
                  name={"nic"}
                  required
                  type="number"
                  autocomplete="new-password"
                  placeholder="National Identity Number*"
                />

                <FormInputField
                  name={"phone"}
                  type="number"
                  required
                  placeholder="030X-XXXXXXX*"
                />

                <FormInputField
                  required
                  placeholder="Date of Birth"
                  type="text"
                  id="dateOfBirth"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  name={"dateOfBirth"}
                  size="30"
                />

                <FormSelectField
                  name={"maritalStatus"}
                  type="text"
                  autocomplete="new-password"
                  required
                  placeholder="Marital Status*"
                >
                  <option value="" disabled selected>
                    Select Marital Status*
                  </option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </FormSelectField>

                <FormSelectField
                  name={"gender"}
                  autocomplete="new-password"
                  placeholder="Gender*"
                  required
                >
                  <option value="" disabled selected>
                    Select Gender*
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </FormSelectField>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                Enter Employment Details
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormSelectField
                  name="role"
                  onClick={(e) => {
                    setRole_check(e.target.value);
                    console.log(role_check);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select Role*
                  </option>
                  <option value="employee">Employee</option>
                  <option value="admin">Admin</option>
                  <option value="supervisor">Supervisor</option>
                </FormSelectField>

                <FormInputField name={"department"} placeholder="Department" />

                <FormInputField
                  type="text"
                  required
                  placeholder="Designation"
                  name={"designation"}
                />

                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?role=supervisor&role=admin&status=Active`}
                  searchCriteria={"username"}
                  isDisabled={role_check !== "admin" ? false : true}
                  placeholder={"Select Supervisor"}
                  name={"supervisedBy"}
                />

                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/shift?&status=Active`}
                  searchCriteria={"shiftName"}
                  displayName={"name"}
                  // isDisabled={role_check !== "admin" ? false : true}
                  name={"shiftId"}
                  placeholder={"Select Shift*"}
                />

                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/leaveSetting?&status=Active`}
                  searchCriteria={"name"}
                  isDisabled={role_check !== "admin" ? false : true}
                  placeholder={"Select Leave Type"}
                  name={"leave"}
                />

                <FormInputField
                  required
                  placeholder="Joining Date"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  id="JoiningDate"
                  name="JoiningDate"
                  size="30"
                />
              </div>

              {/* <FormInputField
                required
                placeholder="CustomID"
                disabled={localStorage.getItem("adminProf")}
                name="test"
              /> */}
            </div>

            <div className="w-full">
              <h2 className="text-lg font-bold mb-2">Salary Details</h2>

              <div className="flex flex-col gap-4">
                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Enter Salary Manually</h4>
                    <input
                      name="manual"
                      type="radio"
                      value={"manual"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                      {...register("salaryMethod")}
                    />
                  </div>
                </div>

                <div
                  className={
                    idStatus === "manual"
                      ? "grid grid-cols-1 sm:grid-cols-2 gap-3"
                      : "hidden"
                  }
                >
                  <FormInputField
                    placeholder="Base Salary (in PKR)*"
                    type="number"
                    disabled={idStatus === "manual" ? false : true}
                    required={idStatus === "manual" ? true : false}
                    name={"salary"}
                  />
                  <FormInputField
                    placeholder="Health Allowance*"
                    type="number"
                    disabled={idStatus === "manual" ? false : true}
                    required={idStatus === "manual" ? true : false}
                    name={"health_allowance"}
                  />
                  <FormInputField
                    placeholder="Fuel Allowance*"
                    type="number"
                    disabled={idStatus === "manual" ? false : true}
                    required={idStatus === "manual" ? true : false}
                    name={"fuel_allowance"}
                  />
                </div>

                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Assign a Grade</h4>
                    <input
                      name="manual"
                      type="radio"
                      value={"grade"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                      {...register("salaryMethod")}
                    />
                  </div>
                  <div>
                    <p className="text-red-700 mt-2 text-left">
                      {errors.salaryMethod?.message}
                    </p>
                  </div>
                </div>
                <div className={idStatus === "grade" ? "" : "hidden"}>
                  <FormReactSelect
                    apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/grade?&status=Active`}
                    searchCriteria={"name"}
                    isDisabled={idStatus === "grade" ? false : true}
                    name={"gradeId"}
                    placeholder={"Select Grade*"}
                  />
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                Enter Emergency Details
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  name={"emerg_name"}
                  required
                  placeholder="Enter Name*"
                />
                <FormInputField
                  name={"emerg_contact"}
                  type="number"
                  required
                  placeholder="030X-XXXXXXX*"
                />
              </div>
            </div>

            <div>
              <p className="text-red-700 mt-2 text-left">{error}</p>
            </div>

            {console.log(errors)}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="green p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        show={editModal}
        className=" w-4/5 md:w-3/5"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center "
          >
            <FormImageField
              name={"file"}
              edit={editForm}
              value={selectedUser.photo}
              prefix={`${process.env.REACT_APP_IMAGE_PROFILE}`}
            />

            <div>
              <h2 className="text-lg font-bold mb-2">User Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  placeholder="Name*"
                  disabled={!editForm}
                  defaultValue={selectedUser.username}
                  name={"username"}
                />

                <FormInputField
                  name={"email"}
                  type="email"
                  disabled={!editForm}
                  defaultValue={selectedUser.email}
                  autocomplete="new-password"
                  placeholder="Email*"
                />

                {/* <FormInputField
                  type="password"
                  autocomplete="new-password"
                  required
                  disabled={!editForm}
                  placeholder="Password*"
                  name={"password"}
                /> */}

                <FormInputField
                  name={"nic"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.nic}
                  autocomplete="new-password"
                  placeholder="National Identity Number*"
                />

                <FormInputField
                  name={"phone"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.phone}
                  required
                  placeholder="030X-XXXXXXX*"
                />

                <FormInputField
                  required
                  placeholder="Date of Birth"
                  type="date"
                  disabled={!editForm}
                  id="dateOfBirth"
                  name={"dateOfBirth"}
                  size="30"
                />

                <FormSelectField
                  name={"maritalStatus"}
                  type="text"
                  autocomplete="new-password"
                  placeholder="Marital Status*"
                  disabled={!editForm}
                >
                  <option
                    defaultValue={selectedUser.maritalStatus}
                    disabled
                    selected
                  >
                    {selectedUser.maritalStatus}
                  </option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </FormSelectField>

                <FormSelectField
                  name={"gender"}
                  autocomplete="new-password"
                  disabled={!editForm}
                >
                  <option defaultValue={selectedUser.gender} disabled selected>
                    {selectedUser.gender}
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </FormSelectField>

                <FormInputField
                  disabled
                  defaultValue={selectedUser.test}
                  placeholder="UserID"
                  name="test"
                />
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">Employment Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormSelectField
                  name="role"
                  onClick={(e) => {
                    setRole_check(e.target.value);
                    console.log(role_check);
                  }}
                  disabled={!editForm}
                  required
                >
                  <option defaultValue={selectedUser.role} disabled selected>
                    {selectedUser.role}
                  </option>
                  <option value="employee">Employee</option>
                  <option value="admin">Admin</option>
                  <option value="supervisor">Supervisor</option>
                </FormSelectField>

                <FormInputField
                  name={"department"}
                  disabled={!editForm}
                  defaultValue={selectedUser.department}
                  placeholder="Department*"
                />

                <FormInputField
                  type="text"
                  required
                  disabled={!editForm}
                  defaultValue={selectedUser.designation}
                  placeholder="Designation*"
                  name={"designation"}
                />

                <FormInputField
                  required
                  placeholder="Joining Date"
                  type="date"
                  disabled={!editForm}
                  id="JoiningDate"
                  name="JoiningDate"
                  size="30"
                />
              </div>
            </div>

            <div className="w-full">
              <h2 className="text-lg font-bold mb-2">Salary Details</h2>
              <p
                className="mt-1 mb-4 text-sm text-gray-500"
                id="file_input_help"
              >
                To edit salary assignment method, navigate to the Grades tab
                under Payroll.
              </p>

              <div className="flex flex-col gap-4">
                <div>
                  <div
                    className="flex flex-row justify-between form-check"
                    onChange={(e) => setIdStatus(e.target.value)}
                  >
                    <h4>Salary</h4>
                    <input
                      name="manual"
                      type="radio"
                      disabled
                      defaultChecked={!selectedUser.isGrade}
                      value={"manual"}
                      onChange={(e) => setIdStatus(e.target.value)}
                      className="cursor-pointer w-4"
                    />
                  </div>
                </div>

                <div
                  className={
                    selectedUser.isGrade
                      ? "hidden"
                      : "grid grid-cols-1 sm:grid-cols-2 gap-3"
                  }
                >
                  <FormInputField
                    placeholder="Salary Amount (in PKR)"
                    type="number"
                    defaultValue={selectedUser.salary}
                    disabled
                    name={"salary"}
                  />
                  <FormInputField
                    placeholder="Health Allowance"
                    type="number"
                    defaultValue={selectedUser.health_allowance}
                    disabled
                    name={"health_allowance"}
                  />
                  <FormInputField
                    placeholder="Fuel Allowance"
                    type="number"
                    defaultValue={selectedUser.fuel_allowance}
                    disabled
                    name={"fuel_allowance"}
                  />
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">Emergency Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  name={"emerg_name"}
                  disabled={!editForm}
                  defaultValue={selectedUser.emerg_name}
                  placeholder="Enter Name"
                />
                <FormInputField
                  name={"emerg_contact"}
                  type="number"
                  disabled={!editForm}
                  defaultValue={selectedUser.emerg_contact}
                  placeholder="Enter Contact"
                />
              </div>
            </div>

            <div>
              <p className="text-red-700 mt-2 text-left">{error}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              {editForm === true ? (
                <input
                  type={"submit"}
                  value={"Save Changes"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => setEditForm(true)}
                  type={"button"}
                  className="green p-3  rounded-lg text-white cursor-pointer"
                >
                  Edit{" "}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.deleted_at ? "activate " : "deactivate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => {
              setDeleteModal(false);
            }}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => {
              if (selectedUser.deleted_at) HandleRestoreForm();
              else HandleDeleteForm();
            }}
            value={selectedUser.deleted_at ? "Activate" : "Deactivate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default Users;
