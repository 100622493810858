import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdOutlineClose } from "react-icons/md";

import Image from "../Image";
import AddMember from "../../images/add_member.svg";
import PageLoader from "../PageLoader";

import MultiFileSelect from "./MultiFileSelect";
import { convertToArray, viewImage } from "../Functions";
import APIErrorDisplay from "../ErrorHandling/APIErrorDisplay";
import Submit from "../ModalButtons/Submit";

const EditMultipleImages = ({ id }) => {
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [smallModal, setSmallModel] = useState(false);
  const [users, setUsers] = useState();
  const [error, setError] = useState("");

  //For Users to assign
  const [customSelectValue, setCustomSelectValue] = useState();
  const [userImage, setUserImage] = useState([]);

  //For Users to assign
  const [taskAttachments, setTaskAttachments] = useState([]);

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court/${id}`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data);
          setSelectedUser(res.data.data.images);
          setUsers(res.data.data.images);

          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  const removeUser = (id) => {
    console.log(id);

    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/del-image/${id}`,
        method: `DELETE`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          getInfo();
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });
    }
  };

  const HandleForm = () => {
    console.log(taskAttachments);

    if (!disabled) {
      setDisabled(true);

      setError();

      let formData = new FormData();

      //appending images
      for (let index = 0; index < taskAttachments.length; index++) {
        formData.append("images[]", taskAttachments[index]);
      }

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/add-image/${id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setTaskAttachments([]);
          getInfo();
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.error);
          setDisabled(false);
          return Promise.reject();
        });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-row gap-1 flex-wrap">
          {users &&
            users.map((file) => (
              <div key={file.id} className="relative p-2 ">
                <ImCross
                  onClick={() => {
                    removeUser(file.id);
                  }}
                  size={16}
                  className="red text-white p-1 rounded-full cursor-pointer absolute right-1 top-1 ml-auto mr-0"
                />

                <Image
                  src={`${process.env.REACT_APP_IMAGE}/${file.image}`}
                  className="h-20 w-20 rounded-lg cursor-pointer"
                  onClick={() =>
                    viewImage(`${process.env.REACT_APP_IMAGE}/${file.image}`)
                  }
                  alt="upload"
                />

                {/* <div className="mt-2 p-1  flex flex-col items-center overflow-clip">
                  <span className="font-thin w-24 text-ellipsis text-center whitespace-normal break-after-all">
                    {file}
                  </span>
                </div> */}
                {/* <button
              className="red text-white p-1.5 rounded font-thin text-xs mt-3 "
              type="button"
              onClick={() => deleteHandler(image)}
            >
              Remove
            </button> */}
              </div>
              //   <div className="">
              //     <ImCross
              //       size={16}
              //       className="red text-white p-1 rounded-full cursor-pointer ml-auto mr-"
              //     />
              //     <Image
              //       className="h-12 w-12"
              //       src={"www.random.com"}
              //       label={users.username}
              //     />
              //   </div>
            ))}
          <div className="relative p-2 ">
            <Image
              src={AddMember}
              className="h-16 w-16 rounded-lg cursor-pointer hover:opacity-70"
              alt="upload"
              onClick={() => setSmallModel(true)}
            />

            <div className="mt-2 p-1  flex flex-col items-center overflow-clip">
              <span className="font-thin w-24 text-ellipsis text-center whitespace-normal break-after-all">
                {"Add Files"}
              </span>
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className={
              smallModal
                ? "flex flex-col gap-6 justify-start align-center"
                : "hidden"
            }
          >
            <div className="flex flex-col gap-5 md:w-3/6">
              <div className="flex flex-row justify-between items-center gap-4">
                <h3 className="text-base">Add Files</h3>
                <MdOutlineClose
                  size={20}
                  className="text-gray-500 hover:opacity-70 cursor-pointer"
                  onClick={() => setSmallModel(false)}
                />
              </div>

              <div className="self-start">
                <MultiFileSelect
                  selectedImages={taskAttachments}
                  setSelectedImages={setTaskAttachments}
                />
              </div>

              <Submit
                className="green text-gray-600 rounded-lg p-2  md:w-1/3 min-w-fit cursor-pointer text-center"
                value="Save"
                type="submit"
              />
            </div>
          </form>
        </FormProvider>
      </div>

      <APIErrorDisplay error={error} />

      {disabled && <PageLoader size={30} type="ThreeDots" />}
    </>
  );
};

export default EditMultipleImages;
