import { useEffect } from "react";
import { useState } from "react";
import ErrorDisplay from "./ErrorDisplay";

const APIErrorDisplay = ({ error }) => {
  const [errorArray, setErrorArray] = useState();

  useEffect(() => {
    console.log(error);
    if (error) {
      setErrorArray(Object.values(error));
    }
  }, [error]);

  if (error)
    return (
      <>
        {" "}
        <div className="p-4 bg-redCustom rounded-lg text-white">
          {errorArray &&
            errorArray.map((errorArray, i) => (
              <p className=" text-left" key={i}>
                {errorArray[0]}
              </p>
            ))}
        </div>
      </>
    );
  else return null;
};

export default APIErrorDisplay;
