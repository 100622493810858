import PieChart from "../../../components/Dashboard/PieChart";
import { useEffect, useState } from "react";
import axios from "axios";
import PageLoader from "../../../components/PageLoader";
import HoriBarGraph from "../../../components/Dashboard/HoriBarGraph";
import LineGraph from "../../../components/Dashboard/LineGraph";
import VertiBarGraph from "../../../components/Dashboard/VertiBarGraph";
import { convertToArray } from "../../../components/Functions";
import ErrorDisplay from "../../../components/ErrorHandling/ErrorDisplay";

const Overview = () => {
  const [loaded, setLoaded] = useState(false);
  const [paidUsers, setPaidUsers] = useState(0);
  const [freeUsers, setFreeUsers] = useState(0);
  const [withdrawal, setWithdrawal] = useState({
    dataSet: [],
    labels: [],
  });
  const [payments, setPayments] = useState({
    dataSet: [],
    labels: [],
  });
  const [paymentsFilter, setPaymentsFilter] = useState("1");
  const [withdrawalFilter, setWithdrawalFilter] = useState("1");
  const [reqMonth, setReqMonth] = useState();
  const [error, setError] = useState("");

  //For fetching data
  useEffect(() => {
    fetchingPieData();
  }, [withdrawalFilter]);

  useEffect(() => {
    fetchingPieData();
  }, [paymentsFilter]);

  const fetchingPieData = () => {
    setLoaded(false);
    setError("");

    //Fetching monthly withdrawals
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/payments?month=${withdrawalFilter}&status=pending`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.data);
        setWithdrawal({
          dataSet: convertToArray(res.data.data, `requests`),
          labels: convertToArray(res.data.data, `date`),
        });
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });

    //Fetching released payments
    const promise1 = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/payments?month=${paymentsFilter}&status=released`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res.data.data);
        setPayments({
          dataSet: convertToArray(res.data.data, `requests`),
          labels: convertToArray(res.data.data, `date`),
        });
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });

    Promise.all([promise, promise1]).then((values) => {
      setLoaded(true);
    });
  };

  if (loaded === false) {
    return (
      <div className="mt-28">
        <PageLoader type="ThreeDots" />
        <div className="flex flex-row justify-center">
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </div>
      </div>
    );
  } else
    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-5 p-12 items-center justify-center md:justify-start w-full">
          <LineGraph
            chartLabel="Withdrawal Requests"
            dropdownSelect={() => {
              return (
                <select
                  className="border-0 bg-transparent text-white font-semibold"
                  onChange={(e) => {
                    setWithdrawalFilter(e.target.value);
                  }}
                  value={withdrawalFilter}
                >
                  <option value={1} className="bg-primary">
                    Jan
                  </option>
                  <option value={2} className="bg-primary">
                    Feb
                  </option>
                  <option value={3} className="bg-primary">
                    March
                  </option>
                  <option value={4} className="bg-primary">
                    Apr
                  </option>
                  <option value={5} className="bg-primary">
                    May
                  </option>
                  <option value={6} className="bg-primary">
                    June
                  </option>
                  <option value={7} className="bg-primary">
                    July
                  </option>
                  <option value={8} className="bg-primary">
                    Aug
                  </option>
                  <option value={9} className="bg-primary">
                    Sep
                  </option>
                  <option value={10} className="bg-primary">
                    Oct
                  </option>
                  <option value={11} className="bg-primary">
                    Nov
                  </option>
                  <option value={12} className="bg-primary">
                    Dec
                  </option>
                </select>
              );
            }}
            dataSet={withdrawal.dataSet}
            labels={withdrawal.labels}
            tooltipLabel="Requests"
          />

          {/* <VertiBarGraph
            chartLabel="Monthly Withdrawal Requests"
            dataSet={[22, 23, 54, 43, 63]}
            labels={[
              "2022-11-16",
              "2022-11-17",
              "2022-11-18",
              "2022-11-19",
              "2022-11-20",
            ]}
            // dataSet={withdrawal.dataSet}
            // labels={withdrawal.labels}
            tooltipLabel="Requests"
          />

          <PieChart
            // dataSet={[freeUsers, paidUsers]}
            dataSet={[22, 75]}
            labels={["Free", "Premium"]}
            chartLabel="Users"
          /> */}

          <HoriBarGraph
            chartLabel="Released Payments"
            dropdownSelect={() => {
              return (
                <select
                  className="border-0 bg-transparent text-white font-semibold"
                  onChange={(e) => {
                    setPaymentsFilter(e.target.value);
                  }}
                  value={paymentsFilter}
                >
                  <option value={1} className="bg-primary">
                    Jan
                  </option>
                  <option value={2} className="bg-primary">
                    Feb
                  </option>
                  <option value={3} className="bg-primary">
                    March
                  </option>
                  <option value={4} className="bg-primary">
                    Apr
                  </option>
                  <option value={5} className="bg-primary">
                    May
                  </option>
                  <option value={6} className="bg-primary">
                    June
                  </option>
                  <option value={7} className="bg-primary">
                    July
                  </option>
                  <option value={8} className="bg-primary">
                    Aug
                  </option>
                  <option value={9} className="bg-primary">
                    Sep
                  </option>
                  <option value={10} className="bg-primary">
                    Oct
                  </option>
                  <option value={11} className="bg-primary">
                    Nov
                  </option>
                  <option value={12} className="bg-primary">
                    Dec
                  </option>
                </select>
              );
            }}
            dataSet={payments.dataSet}
            labels={payments.labels}
            tooltipLabel="Payments"
          />
        </div>
      </>
    );
};

export default Overview;
