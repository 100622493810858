import "./App.css";
import { useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// config
import WithoutNav from "./routes/WithoutNav";
import WithNav from "./routes/WithNav";
import NavContext from "./Context/NavContext";
import ProtectedRoute from "./components/protected-routes/ProtectedRoute";
import ErrorBoundary from "./components/ErrorHandling/ErrorBoundary";

//public
import Login from "./pages/Login";
import SignUpWait from "./pages/SignUpWait";
import SignUp from "./pages/SignUp";
import PageNotFound from "./pages/PageNotFound";
import ForgotPassword from "./pages/ForgotPassword";
import EmailSent from "./pages/EmailSent";
import ResetPassword from "./pages/ResetPassword";

//superadmin
import SUdashboard from "./pages/SuperAdmin/Dashboard/Dashboard";
import SUoverview from "./pages/SuperAdmin/Dashboard/Overview";
import SUstats from "./pages/SuperAdmin/Dashboard/Stats";
import Users from "./pages/SuperAdmin/Users/Users";
import Courts from "./pages/SuperAdmin/Courts/Courts";
import Deals from "./pages/SuperAdmin/Deals/Deals";
import SUcourtPayments from "./pages/SuperAdmin/Payments/CourtPayments";
import SUviewPremPayments from "./pages/SuperAdmin/Payments/ViewPremPayments";

//admin
import ADdashboard from "./pages/CourtUser/Dashboard/Dashboard";
import ADoverview from "./pages/CourtUser/Dashboard/Overview";
import ADstats from "./pages/CourtUser/Dashboard/Stats";
import ADcourtPayments from "./pages/CourtUser/Payments/CourtPayments";
import CourtSetting from "./pages/CourtUser/CourtSetting/CourtSetting";

function App() {
  const [nav, setNav] = useState(true);

  const value = { nav, setNav };

  return (
    <ErrorBoundary>
      <div className="App">
        <NavContext.Provider value={value}>
          <Toaster />

          <BrowserRouter>
            <Routes>
              <Route element={<WithoutNav />}>
                {/* <Route path="/signup" element={<SignUp />} />
                <Route path="/signup-wait" element={<SignUpWait />} /> */}
                <Route path="/forgot" element={<ForgotPassword />} />
                <Route path="/email-sent" element={<EmailSent />} />
                <Route path="/resetpassword/:id" element={<ResetPassword />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Login />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>

              <Route element={<WithNav />}>
                {/* SUPERADMIN */}
                <Route
                  path="/SU/*"
                  element={<ProtectedRoute user={"super-admin"} />}
                >
                  <Route path="dashboard/*" element={<SUdashboard />}>
                    <Route index element={<SUoverview />} />
                    <Route path="overview" element={<SUoverview />} />
                    <Route path="stats" element={<SUstats />} />
                  </Route>

                  <Route path="users" element={<Users />} />
                  <Route path="courts" element={<Courts />} />
                  <Route path="deals" element={<Deals />} />
                  <Route path="payments/*">
                    <Route index element={<SUcourtPayments />} />
                    <Route
                      path="prem-payments"
                      element={<SUviewPremPayments />}
                    />
                  </Route>
                </Route>

                {/* ADMIN */}

                <Route
                  path="/AD/*"
                  element={<ProtectedRoute user={"court-user"} />}
                >
                  <Route path="dashboard/*" element={<ADdashboard />}>
                    <Route index element={<ADoverview />} />
                    <Route path="overview" element={<ADoverview />} />
                    <Route path="stats" element={<ADstats />} />
                  </Route>

                  <Route path="court-setting/*">
                    <Route index element={<CourtSetting />} />
                  </Route>

                  <Route path="payments/*">
                    <Route index element={<ADcourtPayments />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </NavContext.Provider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
