import { Blocks, TailSpin, ThreeDots } from "react-loader-spinner";

const PageLoader = ({ type = "ThreeDots", size = 80 }) => {
  const selectLoader = (type) => {
    if (type === "ThreeDots") {
      return (
        <ThreeDots
          height={`${size}`}
          width={`${size}`}
          color="#EA0E76"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      );
    } else if (type === "TailSpin") {
      return (
        <TailSpin
          height={`${size}`}
          width={`${size}`}
          color="#EA0E76"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      );
    }
  };

  return (
    <>
      <div className="grid h-5/6 mx-auto place-items-center overflow-auto">
        {selectLoader(type)}
      </div>
    </>
  );
};

export default PageLoader;
