import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  elements: {
    line: {
      fillColor: "#EA0E76",
      width: 6,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "rgb(255, 99, 132)",
        font: {
          family: "Montserrat", // Add your font here to change the font of your legend label
        },
      },
    },
  },

  scales: {
    // to remove the labels
    x: {
      gridLines: {
        zeroLineColor: "#5D2F45",
      },
      ticks: {
        color: "white",
        maxRotation: 0,
        minRotation: 0,
      },

      // to remove the x-axis grid
      grid: {
        drawBorder: true,

        color: "#5D2F45",
      },
    },
    // to remove the y-axis labels
    y: {
      gridLines: {
        zeroLineColor: "white",
      },

      ticks: {
        color: "white",
        stepSize: 1,
      },
      // to remove the y-axis grid
      grid: {
        drawBorder: true,

        color: "#5D2F45",
      },
    },
  },
};

const LineGraph = ({
  chartLabel,
  labels,
  dataSet,
  dropdownSelect,
  tooltipLabel,
}) => {
  const [data, setData] = useState({
    labels: labels,
    datasets: [
      {
        label: tooltipLabel,
        data: dataSet,
        borderRadius: 200,
        borderColor: "#EA0E76",
        fill: "#EA0E76",
        tension: 0.2,
        pointRadius: 4,
        pointBackgroundColor: "#EA0E76",
      },
    ],
  });

  return (
    <>
      <div className="bg-primary p-6 rounded-3xl flex flex-col gap-4 w-full items-center h-full text-xl 2xl:text-2xl">
        <div className="flex flex-row justify-between w-full">
          <h2 className="text-white  text-left self-start font-semibold">
            {chartLabel}
          </h2>

          {dropdownSelect()}
        </div>

        <div className=" self-center w-full">
          <Line options={options} data={data} />
        </div>
      </div>
    </>
  );
};

export default LineGraph;
