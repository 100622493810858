import Image from "../Image";
import profile from "../../images/table/default_profile.svg";

import { RxCross2 } from "react-icons/rx";

const DealsDisplay = ({
  name,
  id,
  user_id,
  description,
  opened,
  image,
  deal,
  deleteUser,
  ...otherProps
}) => {
  return (
    <div
      className=" px-6 py-6 bg-greyCustom rounded-2xl min-w-fit relative  cursor-pointer overflow-hidden hover:opacity-80 "
      {...otherProps}
    >
      <div className="absolute top-2.5 right-2.5 p-0.5 red rounded-full z-10 hover:opacity-75">
        <RxCross2
          size={12}
          className="mx-auto my-auto text-white"
          onClick={(e) => {
            deleteUser(deal);
            e.stopPropagation();
          }}
        />
      </div>

      <div className="flex flex-col md:flex-row flex-shrink-0 justify-between  md:gap-8 items-center text-center md:text-start h-full">
        <div className="p-6 w-full">
          <h4 className="font-bold text-base 2xl:text-2xl uppercase">{name}</h4>

          <p className="font-light text-xs 2xl:text-sm mt-3">{description}</p>
        </div>

        <div className=" md:justify-self-end flex flex-col items-center gap-3">
          <Image
            src={`${process.env.REACT_APP_IMAGE}/${image}`}
            className="w-28 p-2 aspect-square rounded-xl "
          />
          <span
            className={`text-xs 2xl:text-base text-right flex flex-row justify-center md:justify-end    ${
              opened == 1 ? "text-green-600" : "text-red-600"
            }`}
          >
            {opened == "1" ? "Open" : "Closed"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DealsDisplay;
