const Edit = ({ ...otherProps }) => {
  return (
    <input
      className="green p-3 font-thin   rounded-lg text-gray-600 cursor-pointer" //styling in app.css
      {...otherProps}
    />
  );
};

export default Edit;
