import axios from "axios";
import { useEffect, useState } from "react";
import Cards from "../../../components/Dashboard/Cards";
import ErrorDisplay from "../../../components/ErrorHandling/ErrorDisplay";
import PageLoader from "../../../components/PageLoader";

const Stats = () => {
  //For showing loader while API does its work
  const [loaded, setLoaded] = useState(false);
  const [paidUsers, setPaidUsers] = useState();
  const [freeUsers, setFreeUsers] = useState();
  const [courtOwners, setCourtOwners] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [error, setError] = useState("");

  //For searching
  useEffect(() => {
    fetchingData();
  }, []);

  const fetchingData = (page) => {
    setLoaded(false);
    setError("");

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/total-users`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setLoaded(true);
        setPaidUsers(res.data.data.premium);
        setFreeUsers(res.data.data.freemium);
        setCourtOwners(res.data.data.court_owners);
        setTotalUsers(res.data.data.total);
      })

      // Catch errors if any
      .catch((err) => {
        setError("An error has occurred");
        return Promise.reject();
      });
  };

  if (loaded === false) {
    return (
      <div className="mt-28 ">
        <PageLoader type="ThreeDots" />
        <div className="flex flex-row justify-center">
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </div>
      </div>
    );
  } else
    return (
      <>
        <div className="flex flex-row flex-wrap gap-5 p-12 items-center justify-center md:justify-start">
          <Cards heading={"Total Users"} number={totalUsers} link="/SU/users" />
          <Cards
            heading={"Court Owners"}
            number={courtOwners}
            link="/SU/users"
          />
          <Cards
            heading={"Premium Users"}
            number={paidUsers}
            link="/SU/users"
          />
          <Cards heading={"Free Users"} number={freeUsers} link="/SU/users" />
        </div>
      </>
    );
};

export default Stats;
