import Navbar from "../components/Navbar/Navbar";
import { Outlet } from "react-router";
import Container from "../components/Container/Container";
import RightNavbar from "../components/RightNavbar/RightNavbar";
import NavbarSuperVisor from "../components/Navbar/NavbarSuperVisor";
import NavbarAdmin from "../components/Navbar/NavbarAdmin";

export default ({ setModules }) => {
  const chooseUser = () => {
    if (localStorage.getItem("role") === "super-admin") {
      return <Navbar />;
    }

    if (localStorage.getItem("role") === "court-user") {
      return <NavbarAdmin />;
    }

    if (localStorage.getItem("role") === "supervisor") {
      return <NavbarSuperVisor />;
    }
  };

  return (
    <>
      {chooseUser()}
      <Container stickyNav={<RightNavbar />} content={<Outlet />} />
    </>
  );
};
