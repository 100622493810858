import React, { useState } from "react";
import Image from "../Image";
import cross from "../../images//modal/close-circle.svg";
import { ImCross } from "react-icons/im";

const MultiFileSelect = ({
  selectedImages,
  setSelectedImages,
  edit = false,
}) => {
  const imagePreview = (file) => {
    return URL.createObjectURL(file);
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return file;
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  return (
    <section className="mb-6">
      {/* {selectedImages.length > 0 &&
        (selectedImages.length > 10 ? (
          <p className="error">
            You can't upload more than 10 images! <br />
            <span>
              please delete <b> {selectedImages.length - 10} </b> of them{" "}
            </span>
          </p>
        ) : (
          <button
            className="upload-btn"
            onClick={() => {
              console.log(selectedImages);
            }}
          >
            UPLOAD {selectedImages.length} IMAGE
            {selectedImages.length === 1 ? "" : "S"}
          </button>
        ))} */}

      {/* <button
        className="upload-btn"
        onClick={() => {
          console.log(selectedImages);
        }}
      >
        UPLOAD {selectedImages.length} IMAGE
        {selectedImages.length === 1 ? "" : "S"}
      </button> */}

      <div className={`flex flex-row flex-wrap gap-7 `}>
        {selectedImages &&
          selectedImages.map((image, index) => {
            return (
              <div key={index} className="relative p-2">
                <ImCross
                  onClick={() => deleteHandler(image)}
                  size={16}
                  className="red text-white p-1 rounded-full cursor-pointer absolute right-0 top-0 ml-auto mr-0"
                />

                <Image
                  src={imagePreview(image)}
                  className="h-20 w-20 rounded-lg "
                  alt="upload"
                />

                {/* <div className="mt-2 p-1  flex flex-col items-center overflow-clip">
                  <span className="font-thin w-24 text-ellipsis text-center whitespace-normal break-after-all">
                    {image.name}
                  </span>
                </div> */}
                {/* <button
                  className="red text-white p-1.5 rounded font-thin text-xs mt-3 "
                  type="button"
                  onClick={() => deleteHandler(image)}
                >
                  Remove
                </button> */}
              </div>
            );
          })}
      </div>

      {/* sorry for the hack xd */}
      {selectedImages.length > 0 && <br />}
      {selectedImages.length > 0 && <br />}

      <label className="py-2 px-4 cursor-pointer bg-[#9F9F9F] text-white text-sm font-thin rounded-md  hover:opacity-80">
        {edit ? "Replace Attachments" : "Add Attachments"}
        <input
          type="file"
          className="hidden"
          name="images"
          onChange={onSelectFile}
          multiple
          accept="image/png , image/jpeg, image/webp"
        />
      </label>
      <input type="file" className="hidden" multiple />
    </section>
  );
};

export default MultiFileSelect;
