import ForgotImg from "../images/forgot_img.svg";
import arrow from "../images/green-arrow.svg";
import { userSchema } from "../components/Validation/ForgotPsSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState } from "react";
import FormInputField from "../components/Form/FormInputField";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const ForgotPassword = () => {
  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // const history = useHistory();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const HandleForm = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/auth/forgotPassword`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },
        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          console.log(res.message);
          //redirecting

          if (res.status) navigate("/signup-wait");
        })
        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setError(err.response.data.error);
          return Promise.reject();
        });
      toast.promise(promise, {
        loading: "Loading",
        success: "Email sent successfully",
        error: "An error has occurred",
      });
    }
  };

  return (
    <div className="grid h-screen w-full place-items-center bg-primary overflow-auto">
      <div className=" flex flex-col md:flex-col items-center text-center gap-4 w-full  p-2  justify-start text-white">
        <img className="h-16 md:h-24 2xl:h-36" src={ForgotImg} alt="" />

        <Link to={"/login"}>
          {/* <img  src={arrow} alt="" /> */}
          <MdOutlineArrowBackIosNew
            className="absolute left-10 top-10 "
            size={50}
          />
        </Link>

        <h1 className="text-3xl font-bold">Forgot Password</h1>

        <h3 className="font-thin 2xl:text-xl">
          Enter email address for <br /> instructions to be sent
        </h3>

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col justify-center gap-3 md:w-1/4"
          >
            <FormInputField
              className="border font-extralight text-sm xl:text-base 2xl:text-xl  px-6 py-4 border-white bg-[#91F0860D] rounded-xl"
              type="email"
              placeholder="Example123@gmail.com"
              autoComplete="off"
              name="email"
            />

            <p className="text-center text-red-700 mt-2 w-full">{error}</p>

            <button
              type="submit"
              className=" px-12 py-3 pink rounded-2xl text-white  self-center text-base black-hover"
            >
              Send
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ForgotPassword;
