import Pagination from "react-responsive-pagination";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";

import Modal from "../../../components/Modal";
import FormImageField from "../../../components/Form/FormImageField";
import FormInputField from "../../../components/Form/FormInputField";
import FormSelectField from "../../../components/Form/FormSelectField";
import FormTextArea from "../../../components/Form/FormTextArea";
import {
  formatDate,
  formatTempDate,
  roleStyling,
  viewImage,
} from "../../../components/Functions";

import FormPasswordField from "../../../components/Form/FormPasswordField";
import CourtsDisplay from "../../../components/Courts/CourtsDisplay";
import MultiFileSelect from "../../../components/Form/MultiFileSelect";
import Submit from "../../../components/ModalButtons/Submit";
import Cancel from "../../../components/ModalButtons/Cancel";
import Edit from "../../../components/ModalButtons/Edit";
import APIErrorDisplay from "../../../components/ErrorHandling/APIErrorDisplay";
import PageLoader from "../../../components/PageLoader";
import Image from "../../../components/Image";
import FormReactSelect from "../../../components/Form/FormReactSelect";
import EditMultipleImages from "../../../components/Form/EditMultipleImages";
import ErrorDisplay from "../../../components/ErrorHandling/ErrorDisplay";

const Courts = (props) => {
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [role_check, setRole_check] = useState();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [court, setCourt] = useState([]);

  const [editForm, setEditForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    status: "",
    designation: "",
    email: "",
    phone: "",
    JoiningDate: "",
    supervisedBy: {
      username: "",
      id: "",
    },
    shiftId: {
      name: "",
      id: "",
    },
    id: "",
    address: "",
    photo: "",
  });

  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  //For pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageRef = useRef(null);

  //For showing loader while API does its work
  const [loaded, setLoaded] = useState(false);

  //For File Attachments
  const [selectedImages, setSelectedImages] = useState([]);

  // For refreshing table
  const tableRef = useRef();
  const modalRef = useRef(null);

  //For rerendering
  const refreshTable = (page) => {
    fetchingData(page);
  };

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const deleteUser = (court) => {
    console.log(court.id);
    console.log(court);
    setSelectedUser(court);
    setDeleteModal(true);
  };

  const editUser = (court) => {
    //reseting component
    setEditModal(true);
    setEditForm(false);
    setSelectedImages([]);
    reset({});
    setModalLoading(true);

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/court/${court.id}`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setSelectedUser(res.data.data);
        setModalLoading(false);

        reset({
          city: res.data.data.city && res.data.data.city.id,
          court_name: res.data.data.name,
          court_rate: res.data.data.court_rate,
          name: res.data.data.court_owner.name,
          deleted_at: res.data.data.deleted_at,
          phone: res.data.data.court_owner.phone,
          email: res.data.data.court_owner.email,
          longitude: res.data.data.longitude,
          latitude: res.data.data.latitude,
          platform_charges: res.data.data.platform_charges,
        });
      })

      // Catch errors if any
      .catch((err) => {
        setError(err.response.data.data);
        return Promise.reject();
      });
  };

  // useEffect(() => {
  //   fetchingData(page);
  // }, []); // <-- empty dependency array

  const fetchingData = (page) => {
    setLoaded(false);
    setError("");
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/court?&name=${searchTerm}&page=${page}`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setLoaded(true);
        setCourt(res.data.data.data);
        setTotalPages(res.data.data.last_page);
        // pageRef.current.scrollIntoView({ behavior: "smooth" }); //scroll back to the top of the list
      })

      // Catch errors if any
      .catch((err) => {
        setError("An error has occurred");
        return Promise.reject();
      });
  };

  //Adding user
  const HandleForm = (data) => {
    // const file = data["file"];
    // data["file"] = file[0];

    console.log(selectedImages);

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      //appending images
      for (let index = 0; index < selectedImages.length; index++) {
        formData.append("images[]", selectedImages[index]);
      }

      // Display the values
      for (const value of formData.values()) {
        console.log(value);
      }

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setModal(false);

          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.data);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Record Added",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    console.log(selectedImages);
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));
      formData.append("_method", "PUT");

      //appending images

      for (let index = 0; index < selectedImages.length; index++) {
        formData.append("images[]", selectedImages[index]);
      }

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court/${selectedUser.id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable(page);
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.data);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated ",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court/${selectedUser.id}`,
        method: `DELETE`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Court Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleRestoreForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court-restore/${selectedUser.id}`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Court Updated",
        error: "An error has occurred",
      });
    }
  };

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function () {
      status = request.status;
      if (request.status == 200) {
        //if(statusText == OK)
        console.log("image exists");
      } else {
        console.log("image doesn't exist");
      }
    };
  }

  const handlePageChange = (page) => {
    setPage(page);
    fetchingData(page);
  };

  //For searching
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm);
      fetchingData();
      setPage(1);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  if (loaded === false) {
    return (
      <>
        <PageLoader type="ThreeDots" />
        <div className="flex flex-row justify-center">
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </div>
      </>
    );
  } else
    return (
      <main ref={pageRef} className="content-container">
        <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
          <div className="flex flex-col md:flex-row justify-between  gap-6 mb-4 ">
            <input
              type="text"
              className="px-5 py-3  border-2 rounded-xl font-thin"
              placeholder="Search User"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <button
              onClick={() => {
                setModal(true);
                reset({});
                setSelectedImages([]);
                setError();
              }}
              className="py-4 px-10 pink text-white font-thin rounded-xl"
            >
              Create Court
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3 gap-4 w-full">
            {court &&
              court.map((court) => (
                <CourtsDisplay
                  onClick={() => {
                    editUser(court);
                  }}
                  name={court.name}
                  city={court.city && court.city.name}
                  owner_name={court.court_owner && court.court_owner.name}
                  owner_phone={court.court_owner && court.court_owner.phone}
                  owner_email={court.court_owner && court.court_owner.email}
                  latitude={court.latitude}
                  longitude={court.longitude}
                  images={court.images}
                  court_rate={court.court_rate}
                  deleted_at={court.deleted_at}
                  key={court.id}
                  court={court}
                  deleteUser={deleteUser}
                />
              ))}
          </div>

          <div className="mt-12">
            <Pagination
              current={page}
              total={totalPages}
              onPageChange={(page) => handlePageChange(page)}
            />
          </div>
        </div>

        {/* Add Modal */}
        <Modal
          show={modal}
          className="w-3/4 lg:w-4/6 xl:w-3/6 md:h-fit "
          bodyClassName="px-5"
          close={() => {
            setModal(false);
          }}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleForm)}
              className="flex flex-col gap-6  text-left w-full font-thin"
            >
              <MultiFileSelect
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
              />

              <FormInputField
                name={"court_name"}
                label={"Court Name"}
                type={"text"}
              />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormReactSelect
                  apiUrl={`${process.env.REACT_APP_BASEURL}/city?`}
                  searchCriteria={"name"}
                  name={"city"}
                  label="City"
                  placeholder={"Search by name"}
                />

                <FormInputField
                  name={"court_rate"}
                  label={"Court Rate"}
                  type={"number"}
                  step=".01"
                />

                <FormInputField
                  name={"name"}
                  label={"Owner's Name"}
                  type={"text"}
                />

                <FormInputField name={"phone"} label={"Phone"} type={"num"} />

                <FormInputField
                  name={"email"}
                  label={"Email"}
                  type={"email"}
                  autoComplete="new-password"
                />

                <FormPasswordField
                  randomGenerator={false}
                  name={"password"}
                  label={"Password"}
                  autoComplete="new-password"
                />

                <FormInputField
                  name={"longitude"}
                  label={"Longitude"}
                  type={"number"}
                  step=".01"
                />

                <FormInputField
                  name={"latitude"}
                  label={"Latitude"}
                  type={"number"}
                  step=".01"
                />
              </div>

              {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

              <FormInputField
                name={"platform_charges"}
                label={"Platform Charges"}
                type={"number"}
                step=".01"
              />

              <APIErrorDisplay error={error} />

              <div className="grid grid-cols-2 gap-4 min-w-fit md:w-2/6 self-center">
                <Cancel
                  onClick={() => setModal(false)}
                  type="button"
                  value="Cancel"
                />
                <Submit type="submit" value="Add" />
              </div>
            </form>
          </FormProvider>
        </Modal>

        {/* Edit User Modal */}
        <Modal
          show={editModal}
          className=" w-4/5 md:w-3/5"
          close={() => {
            setEditModal(false);
            refreshTable(page);
          }}
        >
          {modalLoading ? (
            <div>
              <PageLoader size={50} />
              <APIErrorDisplay error={error} />
            </div>
          ) : (
            <>
              <div className="mb-3">
                <EditMultipleImages id={selectedUser.id} />
              </div>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(HandleEditForm)}
                  className="flex flex-col gap-6  text-left w-full font-thin"
                >
                  <FormInputField
                    name={"court_name"}
                    label={"Court Name"}
                    type={"text"}
                    disabled={!editForm}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormReactSelect
                      apiUrl={`${process.env.REACT_APP_BASEURL}/city?`}
                      searchCriteria={"name"}
                      name={"city"}
                      isDisabled={!editForm}
                      label="City"
                      placeholder={selectedUser.city && selectedUser.city.name}
                    />

                    <FormInputField
                      name={"court_rate"}
                      label={"Court Rate"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />

                    <FormInputField
                      name={"name"}
                      label={"Owner's Name"}
                      type={"text"}
                      disabled={!editForm}
                    />

                    <FormInputField
                      name={"phone"}
                      label={"Phone"}
                      type={"num"}
                      disabled={!editForm}
                    />

                    <FormInputField
                      name={"email"}
                      label={"Email"}
                      type={"email"}
                      autoComplete="new-password"
                      disabled={!editForm}
                    />

                    <FormInputField
                      name={"longitude"}
                      label={"Longitude"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />

                    <FormInputField
                      name={"latitude"}
                      label={"Latitude"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />

                    <FormInputField
                      name={"platform_charges"}
                      label={"Platform Charges"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />
                  </div>

                  {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

                  <APIErrorDisplay error={error} />

                  <div className="grid grid-cols-2 gap-4 min-w-fit md:w-2/6 self-center">
                    <Cancel
                      onClick={() => {
                        setEditModal(false);
                        refreshTable(page);
                      }}
                      type="button"
                      value="Cancel"
                    />
                    {editForm === true ? (
                      <Submit type={"submit"} value={"Save Changes"} />
                    ) : (
                      <Edit
                        onClick={() => setEditForm(true)}
                        type={"button"}
                        value={"Edit"}
                      />
                    )}
                  </div>
                </form>
              </FormProvider>
            </>
          )}
        </Modal>

        {/* Delete User Modal */}
        <Modal
          show={deleteModal}
          close={() => {
            setDeleteModal(false);
          }}
        >
          <h2 className="text-lg">
            Are you sure you want to <br />
            {console.log(selectedUser.deleted_at)}
            {selectedUser.deleted_at === null ? "deactivate " : "activate "}
            {selectedUser.name}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
            <input
              onClick={() => setDeleteModal(false)}
              type="button"
              value="Cancel"
              className="grey p-3 rounded-lg text-white cursor-pointer"
            />
            <input
              type="button"
              onClick={() => {
                selectedUser.deleted_at === null
                  ? HandleDeleteForm()
                  : HandleRestoreForm();
              }}
              value={
                selectedUser.deleted_at === null ? "Deactivate " : "Activate "
              }
              className="red p-3 rounded-lg text-white cursor-pointer"
            />
          </div>
        </Modal>
      </main>
    );
};

export default Courts;
