import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const PieChart = ({ labels, dataSet, chartLabel }) => {
  const [data, setData] = useState({
    labels: labels,
    datasets: [
      {
        label: chartLabel,
        data: dataSet,
        backgroundColor: ["#910a4a", "#EA0E76"],
        borderColor: ["#910a4a", "#EA0E76"],
        borderWidth: 1,
      },
    ],
  });

  const calculatePercentage = (value) => {
    if (dataSet)
      return parseFloat((value / (dataSet[0] + dataSet[1])) * 100).toFixed(2);
  };

  return (
    <div className="bg-primary p-6 rounded-3xl flex flex-col gap-4 w-full items-center h-full ">
      <h2 className="text-white text-xl 2xl:text-2xl text-left self-start font-semibold justify-self-start">
        {chartLabel}
      </h2>
      <div className="h-32 md:h-3/5 min-w-fit">
        <Pie data={data} options={options} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-3  place-items-center text-center bg-white p-5 rounded-xl  w-full md:w-fit">
        <div>
          <div className="flex flex-row gap-3 items-center ">
            <span className="h-2 w-2 rounded-full bg-[#910a4a]"></span>
            <span className="text-gray-700 text-sm">{labels[0]}</span>
          </div>
          <span className="text-[#910a4a] text-lg font-bold ml-3">
            {calculatePercentage(dataSet[0])}%
          </span>
        </div>

        <div className="md:h-3/4 md:w-0.5 md:bg-gray-500 opacity-80 rounded-lg"></div>

        <div>
          <div className="flex flex-row gap-3 items-center">
            <span className="h-2 w-2 rounded-full bg-secondary"></span>
            <span className="text-gray-700 text-sm ">{labels[1]}</span>
          </div>
          <span className="text-secondary text-lg font-bold ml-3">
            {calculatePercentage(dataSet[1])}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
