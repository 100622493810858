import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";

import React, { useState, useRef, useReducer, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";

import Modal from "../../../components/Modal";
import {
  formatDate,
  formatOnlyTime,
  formatTime,
} from "../../../components/Functions";
import PageLoader from "../../../components/PageLoader";
import APIErrorDisplay from "../../../components/ErrorHandling/APIErrorDisplay";
import { useNavigate } from "react-router-dom";
import Image from "../../../components/Image";
import FormImageFieldSmall from "../../../components/Form/FormImageFieldSmall";
import { paymentStatusStyling } from "../../../components/Functions";
import FormInputField from "../../../components/Form/FormInputField";
import Cancel from "../../../components/ModalButtons/Cancel";
import Submit from "../../../components/ModalButtons/Submit";
import FormSelectField from "../../../components/Form/FormSelectField";
import FormReactSelect from "../../../components/Form/FormReactSelect";

const CourtPayments = (props) => {
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [role_check, setRole_check] = useState();
  const [disabled, setDisabled] = useState(false);
  const [idStatus, setIdStatus] = useState();
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    status: "",
    designation: "",
    email: "",
    phone: "",
    JoiningDate: "",
    supervisedBy: {
      username: "",
      id: "",
    },
    shiftId: {
      name: "",
      id: "",
    },
    id: "",
    address: "",
    photo: "",
  });

  //For filtering
  const [paymentStatus, setPaymentStatus] = useState("pending");

  const navigate = useNavigate();

  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");
  // For refreshing table
  const tableRef = useRef();
  const modalRef = useRef(null);

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (court) => {
    //reseting component
    setEditModal(true);
    setEditForm(false);
    reset({});
    setModalLoading(true);

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/court-payment/${court.id}`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setSelectedUser(res.data.data);
        setModalLoading(false);
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err);
        setError(err.response.data.data);
        return Promise.reject();
      });
  };

  //Adding Booking
  const HandleForm = (data) => {
    const time_start = `${data.day} ${data.time_start}:00`;
    const time_end = `${data.day} ${data.time_end}:00`;

    delete data.time_start;
    delete data.time_end;

    data.day = `${data.day} 00:00:00`;

    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      //appending time start and time end
      formData.append("time_start[]", time_start);
      formData.append("time_end[]", time_end);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/booking`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.data);
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Record Added",
        error: "An error has occurred",
      });
    }
  };

  //WithDrawal Request
  const HandleEditForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      // Object.keys(newData).forEach((key) => formData.append(key, newData[key]));
      formData.append("_method", "PUT");

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/withdraw/${selectedUser.id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.data);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Record Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/users/${selectedUser.id}`,
        method: `DELETE`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleRestoreForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/user-restore/${selectedUser.id}`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated",
        error: "An error has occurred",
      });
    }
  };

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function () {
      status = request.status;
      if (request.status == 200) {
        //if(statusText == OK)
        console.log("image exists");
      } else {
        console.log("image doesn't exist");
      }
    };
  }

  const columns = [
    // {
    //   title: "",
    //   field: "photo",
    //   filtering: false,
    //   render: (rowData) => (
    //     <Image
    //       src={
    //         rowData.photo === "no-photo.jpg"
    //           ? profile
    //           : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.photo}`
    //       }
    //       className="h-10 rounded-full"
    //     />
    //   ),
    // },
    {
      title: "NAME",
      field: "name",
      filtering: false,
      render: (rowData) => (
        <>
          {rowData.user && (
            <p className="font-bold text-base">{rowData.user.name}</p>
          )}
          {rowData.user && <p>{rowData.user.email}</p>}
        </>
      ),
    },
    {
      title: "COURT OWNER",
      field: "court.court_owner.name",
      filtering: false,
    },
    {
      title: "BOOKED BY",
      field: "booked_by.name",
      filtering: false,
    },
    { title: "COURT CITY", field: "court.city", filtering: false },
    // {
    //   title: "ROLE",
    //   field: "role",
    //   filtering: false,
    //   render: (rowData) => (
    //     <div style={roleStyling(rowData.role)}>{rowData.role}</div>
    //   ),
    // },
    // { title: "USER ID", field: "test", filtering: false },
    {
      title: "PAYMENT TYPE",
      field: "payment_type",
      filtering: false,
    },
    {
      title: "TOTAL $",
      field: "total_amount",
      filtering: false,
    },
    {
      title: "CREATED DATE",
      field: "created_at",
      filtering: false,
      render: (rowData) => formatDate(rowData.created_at),
    },
    {
      title: "STATUS",
      field: "status",
      filtering: false,
      render: (rowData) => (
        <div style={paymentStatusStyling(rowData.payment_status)}>
          <span className="uppercase text-sm">{rowData.payment_status}</span>
        </div>
      ),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col md:flex-row  gap-6 mb-4 md:absolute z-10 right-10">
          <select
            className="input-form"
            value={paymentStatus}
            onChange={(e) => {
              setPaymentStatus(e.target.value);
              refreshTable();
            }}
          >
            <option value={"pending"}>Pending</option>
            <option value={"paid"}>Paid</option>
            <option value={"unpaid"}>Unpaid</option>
            <option value={"released"}>Released</option>
          </select>

          <button
            onClick={() => {
              setModal(true);
              reset({});

              setError();
            }}
            className="py-4 px-10 pink text-white font-thin rounded-xl whitespace-nowrap"
          >
            Create Booking
          </button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "-58px",
                  marginBottom: "20px",
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          icons={tableIcons}
          title=""
          columns={columns}
          options={{
            filtering: true,
            loadingType: "linear",
            searchFieldStyle: {
              border: "1px solid #CCCCCC ",
              padding: "8px",
              borderRadius: "10px",
            },
            debounceInterval: 700,
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            rowStyle: {
              borderBottom: "null",
            },
            search: true,
            sorting: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            paymentStatus === "paid" && {
              icon: () => (
                <button className="text-white pink px-5 py-2 rounded text-sm">
                  Withdraw
                </button>
              ),
              onClick: (event, rowData) => {
                editUser(rowData);
              },
            },

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${process.env.REACT_APP_BASEURL}/court-payment?`;

              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  console.log(filter);
                  if (filter.value[0] && filter.value.length == 1)
                    return `&${filter.column.field}${filter.operator}${filter.value[0]}`;
                });

                url += filter.join("");
              }

              if (paymentStatus.length) {
                url += `&status=${paymentStatus}`;
              }

              if (query.search) {
                url += "&name=" + query.search;
              }
              url += "&limit=" + query.pageSize;
              url += "&page=" + (query.page + 1);

              console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                  console.log(result);
                  console.log("apicall", result.data.data);
                  resolve({
                    data: result.data.data,
                    page: query.page,
                    totalCount: result.data.total,
                  });
                })
                .catch((error) => console.log("error", error));
              // .then((result) => {
              //   console.log("res", result);
              //   resolve({
              //     data: result.data,
              //     page: result.page - 1,
              //     totalCount: result.total,
              //   });
              // });
            })
          }
        />
      </div>

      {/* Add User Modal */}
      <Modal
        show={modal}
        className="w-3/4 lg:w-4/6 xl:w-3/6 md:h-fit "
        bodyClassName="px-5"
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6  text-left w-full font-thin"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormReactSelect
                apiUrl={`${process.env.REACT_APP_BASEURL}/all-users?`}
                searchCriteria={"name"}
                label="Booking for"
                name={"user_id"}
              />

              <FormInputField
                name={"location"}
                label={"Address"}
                type={"text"}
              />

              <FormInputField name={"day"} label={"Day"} type="date" />

              <FormInputField
                name={"total_amount"}
                label={"Payment Amount"}
                type={"text"}
              />

              <FormInputField
                name={"time_start"}
                label={"Time Start"}
                type={"time"}
                step="3600"
              />

              <FormInputField
                name={"time_end"}
                label={"Time End"}
                type={"time"}
                step="3600"
              />

              {/* <FormSelectField name={"payment_status"} label={"Payment Status"}>
                <option selected value={"Unpaid"}>
                  Unpaid
                </option>
                <option value={"Paid"}>Paid</option>
              </FormSelectField>

              <FormSelectField name={"booking_status"} label={"Booking Status"}>
                <option selected value={"pending"}>
                  Pending
                </option>
                <option value={"confirmed"}>Confirmed</option>
                <option value={"cancelled"}>Cancelled</option>
              </FormSelectField> */}
            </div>

            {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

            <APIErrorDisplay error={error} />

            <div className="grid grid-cols-2 gap-4 min-w-fit md:w-2/6 self-center">
              <Cancel
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
              />
              <Submit type="submit" value="Add" />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        show={editModal}
        className=" w-4/5 md:w-3/5 px-6"
        close={() => {
          setEditModal(false);
        }}
      >
        {modalLoading ? (
          <div>
            <PageLoader size={50} />
            <APIErrorDisplay error={error} />
          </div>
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleEditForm)}
              className="flex flex-col gap-5  text-left w-full font-thin"
            >
              <span className="text-3xl font-semibold">
                {selectedUser.court.name}
              </span>

              <div className="flex flex-row gap-4">
                <Image src="" className="h-20 w-20" />

                <div className="flex flex-col gap-1 justify-center">
                  <span className="font-semibold">
                    {selectedUser.user.name}
                  </span>
                  <span className="font-light"> {selectedUser.user.email}</span>
                </div>
              </div>

              <div className="flex flex-row items-start gap-3">
                <div className="flex flex-col gap-3">
                  <p className="font-bold text-base">Payment Date:</p>
                  <p className="font-bold text-base">Match Date:</p>
                  <p className="font-bold text-base">Match Slot:</p>
                  <p className="font-bold text-base">Booked By:</p>

                  <p className="font-bold text-base">Total Amount:</p>
                </div>

                <div className="flex flex-col gap-3">
                  <p className=" text-base">10/2/2022</p>

                  <p className=" text-base">
                    {selectedUser.slot[0] &&
                      formatDate(selectedUser.slot[0].start_time)}
                  </p>
                  <p className=" text-base">
                    {`${
                      selectedUser.slot[0] &&
                      formatOnlyTime(selectedUser.slot[0].start_time)
                    }
                     - 
                    ${
                      selectedUser.slot[0] &&
                      formatOnlyTime(selectedUser.slot[0].end_time)
                    }`}
                  </p>

                  <p className="text-base">
                    {selectedUser.booked_by
                      ? selectedUser.booked_by.email
                      : "-"}
                  </p>

                  <p className="text-base">${selectedUser.total_amount}</p>
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-end items-center gap-6 mb-3">
                {/* <FormImageFieldSmall name="image" /> */}
                <button className="py-2 px-3 pink text-white rounded w-1/4 min-w-fit self-center ">
                  Request Withdrawal
                </button>
              </div>

              {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

              <APIErrorDisplay error={error} />
            </form>
          </FormProvider>
        )}
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.active == "1" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => {
              setDeleteModal(false);
            }}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => {
              if (selectedUser.active === "1") HandleDeleteForm();
              else HandleRestoreForm();
            }}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default CourtPayments;
