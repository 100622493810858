import logo from "../images/logo.svg";
import { userSchema } from "../components/Validation/LoginSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import eye from "../images/eye.svg";
import eye_slash from "../images/eye-slash.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Oval, ThreeDots } from "react-loader-spinner";
import FormInputField from "./../components/Form/FormInputField";
import FormPasswordField from "./../components/Form/FormPasswordField";

const Login = () => {
  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // const history = useHistory();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();
      setLoading(true);

      const uninterceptedAxiosInstance = axios.create();

      const promise = uninterceptedAxiosInstance({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/login`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-TOKEN": "ffhgfhgfh",
          // authorization: "your token comes here",
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          // setError(res.message);
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("role", res.data.data.role);
          localStorage.setItem("name", res.data.data.name);

          // localStorage.setItem("adminProf", res.data.user._id);

          setLoading(false);
          //redirecting
          if (res.data.data.token) {
            if (res.data.data.role == "super-admin") {
              console.log(" Logged In");
              navigate("/SU/Dashboard/overview");
            } else if (res.data.data.role == "court-user") {
              console.log(" Logged In");
              navigate("/AD/dashboard");
            } else if (res.data.data.role == "supervisor") {
              console.log(" Logged In");
              navigate("/SV/dashboard");
            } else setError("Invalid Email or Password");
          }
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setDisabled(false);
          setError(err.response.data.data.error);
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Signed in ",
      //   error: "Unable to Sign in",
      // });
    }
  };

  return (
    <div className="grid h-screen w-full place-items-center bg-primary overflow-auto">
      <div className=" flex flex-col md:flex-col items-center text-center gap-3  p-2  justify-start text-white">
        <img className="h-16 md:h-20 2xl:h-36" src={logo} alt="" />

        <div>
          <h1 className="text-lg 2xl:text-3xl whitespace-nowrap font-bold ">
            Sign In
          </h1>
          <h3 className="font-thin text-base 2xl:text-xl">Access Portal</h3>
        </div>

        <div className="flex flex-col gap-3 lg:gap-6 md:shadow-3xl w-full rounded-lg ">
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(HandleForm)}
              className="flex flex-col justify-center gap-2 "
            >
              <FormInputField
                className="border font-extralight text-sm 2xl:text-base 2xl:text-xl  px-6 py-4 border-white bg-[#91F0860D] rounded-xl"
                label={"Email"}
                labelClass="text-left font-normal"
                type="email"
                placeholder="Example123@gmail.com"
                autoComplete="off"
                name="email"
              />

              <FormPasswordField
                className="border font-extralight text-sm 2xl:text-base 2xl:text-xl pl-6 pr-16  py-4 border-white bg-[#91F0860D]  rounded-xl w-full"
                label={"Password"}
                labelClass="text-left font-normal"
                placeholder="********"
                autoComplete="off"
                randomGenerator={false}
                name="password"
              />

              <p className="text-center text-red-700 mt-2  mb-2 w-full">
                {error}
              </p>

              {/* <div className="error-hndl">
            <p className=" mt-4 error-msg">{errors.email?.message}</p>
            <p className=" mt-4 error-msg">{errors.password?.message}</p>
            <p className="error-msg">{error}</p>
          </div> */}

              <button className="px-12 py-3 black rounded-full  text-white pink self-center text-sm 2xl:text-base  whitespace-nowrap">
                Sign In
              </button>

              {loading === true && (
                <div className="mx-auto mt-2">
                  <Oval
                    height="10"
                    width="10"
                    radius="9"
                    color="white"
                    secondaryColor="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              )}
            </form>
          </FormProvider>

          {/* <div>
            <h3 className="text-sm 2xl:text-base ">
              Don't have an account yet?
            </h3>
            <Link
              to={"/signup"}
              className="text-secondary underline text-sm 2xl:text-base"
              href=""
            >
              Sign Up
            </Link>
          </div> */}

          <Link
            to={"/forgot"}
            className=" text-secondary text-sm 2xl:text-base hover:opacity-70"
          >
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
