import profile from "../../../images/table/default_profile.svg";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import FormInputField from "../../../components/Form/FormInputField";
import FormSelectField from "../../../components/Form/FormSelectField";
import {
  formatDate,
  removeEmpty,
  viewImage,
} from "../../../components/Functions";

import PageLoader from "../../../components/PageLoader";
import FormImageField from "../../../components/Form/FormImageField";
import APIErrorDisplay from "../../../components/ErrorHandling/APIErrorDisplay";
import Image from "../../../components/Image";
import EditButton from "../../../components/MaterialTableButtons/EditButton";

import Submit from "../../../components/ModalButtons/Submit";
import Cancel from "../../../components/ModalButtons/Cancel";
import Edit from "../../../components/ModalButtons/Edit";
import EditMultipleImages from "../../../components/Form/EditMultipleImages";
import FormReactSelect from "../../../components/Form/FormReactSelect";
import ErrorDisplay from "../../../components/ErrorHandling/ErrorDisplay";

const CourtSetting = () => {
  const [selectedUser, setSelectedUser] = useState({
    photo: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editModalTimings, setEditModalTimings] = useState(false);
  const [timingsModal, setTimingsModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);

  //For showing loader while API does its work
  const [loaded, setLoaded] = useState(false);

  //For File Attachments
  const [selectedImages, setSelectedImages] = useState([]);

  //For form
  const methods = useForm({
    // resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const getUserInfo = () => {
    if (!disabled) {
      setDisabled(true);
      setLoaded(false);
      setError("");

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res.data.data[0]);
          setSelectedUser(res.data.data[0]);

          reset({});
          setLoaded(true);
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setError("An error has occurred");
          return Promise.reject();
        });

      // toast.promise(promise, {
      //   loading: "Loading",
      //   success: "Data loaded",
      //   error: "An error has occurred",
      // });
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const refreshTable = () => {
    getUserInfo();
  };

  const editUser = () => {
    //reseting component
    setEditModal(true);
    setEditForm(false);
    setSelectedImages([]);
    reset({});
    setModalLoading(true);

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/court`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setSelectedUser(res.data.data[0]);
        setModalLoading(false);

        reset({
          city: res.data.data[0].city && res.data.data[0].city.id,
          court_name: res.data.data[0].name,
          court_rate: res.data.data[0].court_rate,
          name: res.data.data[0].court_owner.name,
          phone: res.data.data[0].court_owner.phone,
          email: res.data.data[0].court_owner.email,
          longitude: res.data.data[0].longitude,
          latitude: res.data.data[0].latitude,
          platform_charges: res.data.data[0].platform_charges,
        });
      })

      // Catch errors if any
      .catch((err) => {
        setError(err.response.data.data);
        return Promise.reject();
      });
  };

  const HandleEditForm = (data) => {
    console.log(selectedImages);
    console.log(data);

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));
      formData.append("_method", "PUT");

      //appending images

      for (let index = 0; index < selectedImages.length; index++) {
        formData.append("images[]", selectedImages[index]);
      }

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court/${selectedUser.id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          setEditForm(false);
          reset();
          getUserInfo();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.data);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Details Updated",
        error: "An error has occurred",
      });
    }
  };

  const editUserTimings = () => {
    //reseting component
    setEditModalTimings(true);
    setEditForm(false);
    reset({});
    setModalLoading(true);

    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/court`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setSelectedUser(res.data.data[0]);
        setModalLoading(false);

        reset({
          start_day:
            res.data.data[0].availability &&
            res.data.data[0].availability.start_day,
          end_day:
            res.data.data[0].availability &&
            res.data.data[0].availability.end_day,
          start_time:
            res.data.data[0].availability &&
            res.data.data[0].availability.start_time.slice(0, -3),
          end_time:
            res.data.data[0].availability &&
            res.data.data[0].availability.end_time.slice(0, -3),
        });
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err.status);
        setError(err.response.data.data);
        return Promise.reject();
      });
  };

  //Adding timings
  const HandleFormTimings = (data) => {
    console.log(data);

    data.start_time = `${data.start_time}:00`;
    data.end_time = `${data.end_time}:00`;
    data["court_id"] = selectedUser.id;

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court-availability`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setTimingsModal(false);
          setEditForm(false);
          reset();
          getUserInfo();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.data);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Details Updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditFormTimings = (data) => {
    console.log(data);

    data.start_time = `${data.start_time}:00`;
    data.end_time = `${data.end_time}:00`;

    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));
      formData.append("_method", "PUT");

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/court-availability/${selectedUser.availability.id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModalTimings(false);
          setEditForm(false);
          reset();
          getUserInfo();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.data);
          setDisabled(false);

          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Details Updated",
        error: "An error has occurred",
      });
    }
  };

  if (loaded === false) {
    return (
      <>
        <PageLoader type="ThreeDots" />
        <div className="flex flex-row justify-center">
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </div>
      </>
    );
  } else
    return (
      <main className="content-container">
        <div className="py-8 px-11 bg-white shadow-2xl rounded-2xl ">
          <div className="flex flex-col gap-8  text-left justify-start">
            <h1 className="text-3xl font-bold ">{selectedUser.name}</h1>

            <div className="flex flex-col md:flex-row gap-6 md:gap-10 items-start">
              <div className="grid grid-cols-1 md:grid-cols-2 justify-start gap-y-2 gap-x-1 items-start">
                <p className="font-semibold">City</p>
                <p className="font-light">
                  {selectedUser.city && selectedUser.city.name}
                </p>

                <p className="font-semibold">Name</p>
                <p className="font-light">
                  {selectedUser.court_owner && selectedUser.court_owner.name}
                </p>

                <p className="font-semibold">Email</p>
                <p className="font-light">
                  {selectedUser.court_owner && selectedUser.court_owner.email}
                </p>

                <p className="font-semibold">Phone</p>
                <p className="font-light">
                  {selectedUser.court_owner && selectedUser.court_owner.phone}
                </p>

                <p className="font-semibold">Size</p>
                <p className="font-light">{`${selectedUser.latitude}x${selectedUser.longitude} sq ft.`}</p>
              </div>

              <EditButton size={24} onClick={() => editUser()} />

              <div className="grid grid-cols-1 md:grid-cols-2 justify-start gap-y-2 gap-x-1 items-start mt-5 md:mt-0">
                <p className="font-semibold">Days Open</p>
                <p className="font-light">
                  {selectedUser.availability &&
                    selectedUser.availability.start_day}{" "}
                  -{" "}
                  {selectedUser.availability &&
                    selectedUser.availability.end_day}
                </p>

                <p className="font-semibold">Timings</p>
                <p className="font-light">
                  {" "}
                  {selectedUser.availability &&
                    selectedUser.availability.start_time.slice(0, -3)}{" "}
                  -{" "}
                  {selectedUser.availability &&
                    selectedUser.availability.end_time.slice(0, -3)}
                </p>
              </div>

              <EditButton
                size={24}
                onClick={() => {
                  selectedUser.availability
                    ? editUserTimings()
                    : setTimingsModal(true);
                }}
              />
            </div>

            <div className="flex flex-col md:flex-row gap-3 w-full">
              {selectedUser.images &&
                selectedUser.images.map((images) => (
                  <Image
                    className="w-44 h-44 rounded-3xl cursor-pointer"
                    src={`${process.env.REACT_APP_IMAGE}/${images.image}`}
                    key={images.id}
                    onClick={() =>
                      viewImage(
                        `${process.env.REACT_APP_IMAGE}/${images.image}`
                      )
                    }
                  />
                ))}
            </div>

            <div className="flex flex-row gap-2 justify-end items-center">
              <span className="font-normal text-sm">Price:</span>
              <span className="font-semibold text-base ms-5">
                ${selectedUser.court_rate}
              </span>
            </div>
          </div>
        </div>

        {/* Edit User Modal */}
        <Modal
          show={editModal}
          className=" w-4/5 md:w-3/5"
          close={() => {
            setEditModal(false);
            getUserInfo();
          }}
        >
          {modalLoading ? (
            <div>
              <PageLoader size={50} />
              <APIErrorDisplay error={error} />
            </div>
          ) : (
            <>
              <div className="mb-3">
                <EditMultipleImages id={selectedUser.id} />
              </div>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(HandleEditForm)}
                  className="flex flex-col gap-6  text-left w-full font-thin"
                >
                  <FormInputField
                    name={"court_name"}
                    label={"Court Name"}
                    type={"text"}
                    disabled={!editForm}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormReactSelect
                      apiUrl={`${process.env.REACT_APP_BASEURL}/city?`}
                      searchCriteria={"name"}
                      name={"city"}
                      isDisabled={!editForm}
                      label="City"
                      placeholder={selectedUser.city && selectedUser.city.name}
                    />

                    <FormInputField
                      name={"court_rate"}
                      label={"Court Rate"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />

                    <FormInputField
                      name={"name"}
                      label={"Owner's Name"}
                      type={"text"}
                      disabled={!editForm}
                    />

                    <FormInputField
                      name={"phone"}
                      label={"Phone"}
                      type={"num"}
                      disabled={!editForm}
                    />

                    <FormInputField
                      name={"email"}
                      label={"Email"}
                      type={"email"}
                      autoComplete="new-password"
                      disabled={!editForm}
                    />

                    <FormInputField
                      name={"longitude"}
                      label={"Longitude"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />

                    <FormInputField
                      name={"latitude"}
                      label={"Latitude"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />

                    <FormInputField
                      name={"platform_charges"}
                      label={"Platform Charges"}
                      type={"number"}
                      disabled={!editForm}
                      step=".01"
                    />
                  </div>

                  {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

                  <APIErrorDisplay error={error} />

                  <div className="grid grid-cols-2 gap-4 min-w-fit md:w-2/6 self-center">
                    <Cancel
                      onClick={() => setEditModal(false)}
                      type="button"
                      value="Cancel"
                    />
                    {editForm === true ? (
                      <Submit type={"submit"} value={"Save Changes"} />
                    ) : (
                      <Edit
                        onClick={() => {
                          setEditForm(true);
                        }}
                        type={"button"}
                        value={"Edit"}
                      />
                    )}
                  </div>
                </form>
              </FormProvider>
            </>
          )}
        </Modal>

        {/* Handle Modal Timings */}
        <Modal
          show={timingsModal}
          className=" w-4/5 md:w-3/5"
          close={() => {
            setTimingsModal(false);
          }}
        >
          <>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(HandleFormTimings)}
                className="flex flex-col gap-6  text-left w-full font-thin"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormSelectField
                    name={"start_day"}
                    label={"Starting Day of the Week"}
                    type={"text"}
                  >
                    <option className="Monday">Monday</option>
                    <option className="Tuesday">Tuesday</option>
                    <option className="Wednesday">Wednesday</option>
                    <option className="Thursday">Thursday</option>
                    <option className="Friday">Friday</option>
                    <option className="Saturday">Saturday</option>
                    <option className="Sunday">Sunday</option>
                  </FormSelectField>

                  <FormSelectField
                    name={"end_day"}
                    label={"Ending Day of the Week"}
                    type={"text"}
                  >
                    <option className="Monday">Monday</option>
                    <option className="Tuesday">Tuesday</option>
                    <option className="Wednesday">Wednesday</option>
                    <option className="Thursday">Thursday</option>
                    <option className="Friday">Friday</option>
                    <option className="Saturday">Saturday</option>
                    <option className="Sunday">Sunday</option>
                  </FormSelectField>

                  <FormInputField
                    name={"start_time"}
                    label={"Opening Time"}
                    type={"time"}
                  />

                  <FormInputField
                    name={"end_time"}
                    label={"Closing Time"}
                    type={"time"}
                  />
                </div>

                {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

                <APIErrorDisplay error={error} />

                <div className="grid grid-cols-2 gap-4 min-w-fit md:w-2/6 self-center">
                  <Cancel
                    onClick={() => setTimingsModal(false)}
                    type="button"
                    value="Cancel"
                  />

                  <Submit type={"submit"} value={"Save Timings"} />
                </div>
              </form>
            </FormProvider>
          </>
        </Modal>

        {/* Edit User Modal Timings */}
        <Modal
          show={editModalTimings}
          className=" w-4/5 md:w-3/5"
          close={() => {
            setEditModalTimings(false);
          }}
        >
          {modalLoading ? (
            <div>
              <PageLoader size={50} />
              <APIErrorDisplay error={error} />
            </div>
          ) : (
            <>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(HandleEditFormTimings)}
                  className="flex flex-col gap-6  text-left w-full font-thin"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormSelectField
                      name={"start_day"}
                      label={"Starting Day of the Week"}
                      type={"text"}
                      disabled={!editForm}
                    >
                      <option className="Monday">Monday</option>
                      <option className="Tuesday">Tuesday</option>
                      <option className="Wednesday">Wednesday</option>
                      <option className="Thursday">Thursday</option>
                      <option className="Friday">Friday</option>
                      <option className="Saturday">Saturday</option>
                      <option className="Sunday">Sunday</option>
                    </FormSelectField>

                    <FormSelectField
                      name={"end_day"}
                      label={"Ending Day of the Week"}
                      type={"text"}
                      disabled={!editForm}
                    >
                      <option className="Monday">Monday</option>
                      <option className="Tuesday">Tuesday</option>
                      <option className="Wednesday">Wednesday</option>
                      <option className="Thursday">Thursday</option>
                      <option className="Friday">Friday</option>
                      <option className="Saturday">Saturday</option>
                      <option className="Sunday">Sunday</option>
                    </FormSelectField>

                    <FormInputField
                      name={"start_time"}
                      label={"Opening Time"}
                      type={"time"}
                      disabled={!editForm}
                    />

                    <FormInputField
                      name={"end_time"}
                      label={"Closing Time"}
                      type={"time"}
                      disabled={!editForm}
                    />
                  </div>

                  {/* <FormReactSelect
              apiUrl={`${process.env.REACT_APP_BASEURL}/api/v1/users?&status=Active`}
              searchCriteria={"username"}
              name={"userId"}
              label="Select User"
            /> */}

                  <APIErrorDisplay error={error} />

                  <div className="grid grid-cols-2 gap-4 min-w-fit md:w-2/6 self-center">
                    <Cancel
                      onClick={() => setEditModalTimings(false)}
                      type="button"
                      value="Cancel"
                    />
                    {editForm === true ? (
                      <Submit type={"submit"} value={"Save Changes"} />
                    ) : (
                      <Edit
                        onClick={() => setEditForm(true)}
                        type={"button"}
                        value={"Edit"}
                      />
                    )}
                  </div>
                </form>
              </FormProvider>
            </>
          )}
        </Modal>
      </main>
    );
};

export default CourtSetting;
