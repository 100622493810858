import React from "react";
import { MdOutlineReportProblem } from "react-icons/md";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className="h-screen w-full bg-white overflow-auto ">
            <div className="flex flex-col items-center text-center gap-5 justify-start md:mt-20 2xl:mt-72 p-8">
              <MdOutlineReportProblem
                className="text-custom-green"
                size={100}
              />
              <h1 className="text-3xl font-bold">An error has occured</h1>

              <button
                onClick={() => window.location.reload()}
                className="black mt-7 px-4 py-2 rounded-lg text-white lg:w-48  self-center text-base "
              >
                Reload
              </button>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
